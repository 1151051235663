import React from "react";
import {
  Button,
  Checkbox,
  Form,
  Input,
  Modal,
  notification,
  Dropdown,
  Space,
} from "antd";
import { useEffect, useState } from "react";
import logo from "../SS09w.png";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { JUZNOW_WEB } from "../config";

const LandingPage = () => {
  const navigate = useNavigate();
  const baseUrl = window.location.origin;
  const code = Cookies.get("refCode");
  const [form] = Form.useForm();
  const [modalTrigger, setmodalTrigger] = useState(false);
  const [modalTriggerInfo, setmodalTriggerInfo] = useState(false);
  const onFinish = (values) => {
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, "0");
    let mm = String(today.getMonth() + 1).padStart(2, "0"); // January is 0!
    let yy = String(today.getFullYear()).slice(-2);
    let { cd1, cd2, cd3, cd4, cd5, cd6, ...rest } = values;
    rest["SignupCode"] = `${dd}${mm}${yy}${code}`;
    rest["RepsBusiness"] = values["RepsBusiness"] === true ? "Y" : "N";
    const FORM = document.createElement("form");
    FORM.method = "POST";
    FORM.action = "https://sendy.sydney.so/subscribe";
    FORM.acceptCharset = "utf-8";
    for (const key in rest) {
      if (rest.hasOwnProperty(key)) {
        const hiddenField = document.createElement("input");
        hiddenField.type = "hidden";
        hiddenField.name = key;
        hiddenField.value = rest[key];
        FORM.appendChild(hiddenField);
      }
    }
    document.body.appendChild(FORM);
    FORM.submit();
  };
  const Partners = [
    {
      name: "TBA",
      url: "https://sydney.so/",
    },
    // {
    //   name: "Juznow.com",
    //   url: "https://juznow.com/",
    // },
    // {
    //   name: "Clublink.co",
    //   url: "https://clublink.co/",
    // },
  ];
  function focusFirstEmptyInput(e) {
    const inputIds = ["cd1", "cd2", "cd3", "cd4", "cd5", "cd6"];
    if (e.target.value !== "") {
      e.target.select();
    } else {
      // If the clicked field is empty, move the focus to the first empty field
      for (let id of inputIds) {
        const input = document.getElementById(id);
        if (input && input.value === "") {
          input.focus();
          break;
        }
      }
    }
  }
  function generateAlphanumeric(length) {
    var result = "";
    var characters = "ABCDEFGHJKMNPQRSTUVWXYZ345689";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  useEffect(() => {
    let url = new URL(window.location.href);
    let refCode = url.searchParams.get("refCode");
    if (refCode) {
      Cookies.set("refCode", refCode, { expires: 365 });
      let lastSixDigits = refCode.slice(-6);
      if (lastSixDigits) {
        form.setFieldsValue({
          cd1: lastSixDigits[0],
          cd2: lastSixDigits[1],
          cd3: lastSixDigits[2],
          cd4: lastSixDigits[3],
          cd5: lastSixDigits[4],
          cd6: lastSixDigits[5],
        });
      }
    }
  }, []);
  const genrateCode = () => {
    if (!code) {
      let randomCode = generateAlphanumeric(6);
      ["cd1", "cd2", "cd3", "cd4", "cd5", "cd6"].forEach((field, index) => {
        form.setFieldValue(field, randomCode[index]);
      });
      randomCode = `${randomCode}`;
      Cookies.set("refCode", randomCode, { expires: 365 });
    } else {
      ["cd1", "cd2", "cd3", "cd4", "cd5", "cd6"].forEach((field, index) => {
        form.setFieldValue(field, code[index]);
      });
    }
  };
  useEffect(() => {
    genrateCode();
  }, []);
  const items = [
    {
      key: "1",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={baseUrl + "/subscription"}
        >
          Subscribe
        </a>
      ),
    },
    {
      key: "2",
      label: (
        <a target="_blank" rel="noopener noreferrer" href={JUZNOW_WEB}>
          View Membership
        </a>
      ),
    },
    {
      key: "3",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={baseUrl + "/verifyQR"}
        >
          Verify Membership
        </a>
      ),
    },
  ];
  return (
    <div>
      <section className="main-cnt">
        <section className="sec-1 p-[12px] md:p-[0px]">
          <div
            style={{
              position: "absolute",
              top: "10px",
              left: "10px",
            }}
          >
            <img
              src={logo}
              alt="Sydney.so"
              className="logo"
              style={{
                height: "50px",
              }}
            />
          </div>
          <div
            style={{
              position: "absolute",
              top: "10px",
              right: "30px",
            }}
          >
            <Dropdown
              menu={{
                items,
              }}
              trigger={["click"]}
              placement="bottom"
              arrow={{
                pointAtCenter: true,
              }}
            >
              <Button>Membership</Button>
            </Dropdown>
          </div>
          <div className="form-container w-[100%] md:w-[538px] m-[200px] p-[18px] md:py-[40px] md:px-[56px]">
            <div className="form w-[100%] md:w-[426px]">
              <div className="heading text-[30px] md:text-[40px]">
                Coming Soon
              </div>
              <div className="mt-[-30px]">
                Join below to notified of our launch
              </div>
              <Form className="w-[100%]" form={form} onFinish={onFinish}>
                <Form.Item
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Please input your name!",
                    },
                  ]}
                >
                  <Input
                    placeholder="Your name"
                    size="large"
                    onChange={(e) => {
                      form.setFieldsValue({
                        name: e.target.value.trim(),
                      });
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                      message: "The input is not valid E-mail!",
                    },
                    {
                      required: true,
                      message: "Please input your E-mail!",
                    },
                  ]}
                >
                  <Input
                    placeholder="Your Email address"
                    size="large"
                    onChange={(e) => {
                      form.setFieldsValue({
                        email: e.target.value.toLowerCase().trim(),
                      });
                    }}
                  />
                </Form.Item>
                <section className="p-0">
                  <Form.Item
                    name="RepsBusiness"
                    valuePropName="checked"
                    className="mb-5 mt-[-20px]"
                  >
                    <section className="flex">
                      <Checkbox />
                      <span className="ml-2">
                        Select if you represent a business{" "}
                      </span>
                      <span
                        className="ml-2 underline cursor-pointer"
                        onClick={() => {
                          setmodalTriggerInfo(true);
                        }}
                      >
                        More Info
                      </span>
                    </section>
                  </Form.Item>
                </section>
                <section className="mb-1 flex justify-end">
                  {/* <div>
                    <span className="text-[#6A6F6F]">Referer Code</span>{" "}
                    <span
                      className="underline cursor-pointer"
                      onClick={() => setmodalTrigger(true)}
                    >
                      What is this ?
                    </span>
                  </div> */}
                  <div
                    className="underline cursor-pointer"
                    onClick={() => {
                      let url = new URL(window.location.href);
                      window.navigator.clipboard.writeText(
                        `${url.origin}${url.pathname}?refCode=${Cookies.get(
                          "refCode"
                        )}`
                      );
                      notification.success({
                        message: "Shareable link copied!",
                      });
                    }}
                  >
                    Share
                  </div>
                </section>
                <section
                // className="flex gap-1 md:gap-3 codeInput h-[50px]"
                // style={{
                //   overflow: "hidden",
                // }}
                >
                  <Form.Item name={"cd1"} hidden>
                    <Input
                      maxLength={1}
                      size="large"
                      style={{ textAlign: "center" }}
                    />
                  </Form.Item>
                  <Form.Item name={"cd2"} hidden>
                    <Input
                      maxLength={1}
                      size="large"
                      style={{ textAlign: "center" }}
                    />
                  </Form.Item>
                  <Form.Item name={"cd3"} hidden>
                    <Input
                      maxLength={1}
                      size="large"
                      style={{ textAlign: "center" }}
                    />
                  </Form.Item>
                  <Form.Item name={"cd4"} hidden>
                    <Input
                      maxLength={1}
                      size="large"
                      style={{ textAlign: "center" }}
                    />
                  </Form.Item>
                  <Form.Item name={"cd5"} hidden>
                    <Input
                      maxLength={1}
                      size="large"
                      style={{ textAlign: "center" }}
                    />
                  </Form.Item>

                  <Form.Item name={"cd6"} hidden>
                    <Input
                      maxLength={1}
                      size="large"
                      style={{ textAlign: "center" }}
                    />
                  </Form.Item>
                  <Form.Item
                    name="list"
                    initialValue="hN5OtjQlDKAx763AQoPv6oLg"
                    hidden
                  />
                  <Form.Item name="subform" initialValue="yes" hidden />
                </section>
                <Button
                  id="submit"
                  size="large"
                  htmlType="submit"
                  block
                  className="bg-[#36393B] text-[white] hover:!bg-[#36393B] hover:!text-[white]"
                >
                  Join
                </Button>
              </Form>
            </div>
          </div>
        </section>
        <section className="p-[12px] md:p-[0px]">
          <section className="sec-2">
            <div className="headingS2">What can I do now?</div>
            <div className="text-[#6A6F6F] txt-sb2 flex gap-3 items-center">
              <img src="/Assets/check.svg" /> have you already signed up to our mailing list above? You can opt out anytime
            </div>
            <div className="text-[#6A6F6F] txt-sb2 flex gap-3 items-center">
              <img src="/Assets/check.svg" /> meanwhile we will be bringing you
              a few offers
            </div>
            <div className="text-[#6A6F6F] items-center flex justify-center gap-5 flex-col md:flex-row">
            <Button as="a"
                className="bg-[#db7617] text-[white] hover:!bg-[#36393B] hover:!text-[white] h-[40px] w-[240px] text-xl ont-[Raleway]"
                  href="https://www.meetup.com/sydney-singles-social/events/303636631/?eventOrigin=group_upcoming_events"
              >
                Social Mixer: 40 - 60
              </Button> 
              {" "}
             
            </div>
            <div className="text-[#6A6F6F] txt-sb2 flex gap-3 items-center">
              <img src="/Assets/check.svg" />
              Subscribe ($8/mth) and attend our events for free (more value soon)
            </div>
            <div className="text-[#6A6F6F] items-center flex justify-center gap-5 flex-col md:flex-row">
            <Button
                className="bg-[#db7617] text-[white] hover:!bg-[#36393B] hover:!text-[white] h-[40px] w-[200px] text-xl ont-[Raleway]"
                onClick={() => {
                  navigate("/subscription");
                }}
              >
                Subscribe
              </Button>{" "}
              <div
                onClick={() => window.open(`${JUZNOW_WEB}`, "_blank")}
                className="underline cursor-pointer text-[#db7617] text-xl font-semibold font-[Raleway]"
              >
                Membership Code
              </div>
            </div>
          </section>
        </section>

        <section className="sec-3">
          <div className="headingS3">Things To Do</div>
          <section className="sec2-3-sub flex flex-col md:flex-row">
            <article className="sec2-3-sub-1 gap-[5px] md:gap-[24px]">
              <div className="flex gap-1 md:gap-3 flex-col">
                <div className="pic1 w-[190px] md:w-[255px]"></div>
                <div className="pic2 w-[190px] md:w-[255px]"></div>
              </div>
              <div className="flex gap-1 md:gap-3 flex-col mt-[15px]">
                <div className="pic3 w-[190px] md:w-[255px]"></div>
                <div className="pic4 w-[190px]md:w-[255px]"></div>
              </div>
            </article>
            <article className="sec2-3-sub-2 p-[12px] md:p-[0px] h-[100%]">
              <div className="sec2sub2-cnt ">
                <div className="flex gap-4">
                  <div className="flex-none">
                    <img src="/Assets/cal.svg" />
                  </div>
                  <div>
                    <div className="sec2sub2head mb-3">
                      Ignite Your Social Scene
                    </div>
                    <div className="sec2sub2sub">
                      From music festivals to art shows and intimate gigs, we
                      will be bringing you lots of opportunities to connect and
                      have a blast! Whether you're flying solo or with your
                      squad, there will always be something for everyone. Get
                      ready to mingle, meet and connect!
                    </div>
                  </div>
                </div>
              </div>
              <div className="sec2sub2-cnt">
                <div className="flex gap-4">
                  <div className="flex-none">
                    <img src="/Assets/cal.svg" />
                  </div>
                  <div>
                    <div className="sec2sub2head mb-3">
                      Variety & Endless Fun
                    </div>
                    <div className="sec2sub2sub">
                      Different types of venues, boat parties, rooftops,
                      beaches. Events range from the most intimate, regular
                      events that can become your second family to spectacular
                      world famous celebrations
                    </div>
                    {/* <div
                      className="sec2sub2subPV mt-3"
                      onClick={() => {
                        window.open(`${baseUrl}/events`, "_blank");
                      }}
                    >
                      Preview Some events
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="sec2sub2-cnt">
                <div className="flex gap-4">
                  <div className="flex-none">
                    <img src="/Assets/cal.svg" />
                  </div>
                  <div>
                    <div className="sec2sub2head mb-3">Businesses</div>
                    <div className="sec2sub2sub">
                      Sign up above and select the checkbox to indicate your
                      interest in working with us. We will be in touch.
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </section>
        </section>
        <section className="sec-4">
          <div className="sec4headeing">Some tasty offers</div>
          <section className="sec4sub flex flex-col md:flex-row">
            <article className="sec4sub1">
              <section>
                <div className="sec4sub1head">Multicultural Cuisines</div>
                <div className="sec4sub1Cont">
                  Hungry for a culinary adventure? Sydney's landscape of flavors
                  just keeps on giving! From harbor-side seafood feasts to
                  hidden gems in laneways, we've got the perfect spot for every
                  craving and occasion.
                </div>
              </section>
              <section>
                <div className="sec4sub1head">Social dining</div>
                <div className="sec4sub1Cont">
                  Food is the ultimate social glue, and Sydney's dining scene is
                  where the magic happens! Whether sharing plates with old
                  friends or breaking bread with new ones, our sampling of the
                  local dining, provides something to satisfy every taste bud.
                </div>
              </section>
            </article>
            <article className="sec4sub2 gap-[5px] md:gap-[20px]">
              <div>
                <div className="pic5 w-[200px] md:w-[294px]"></div>
              </div>
              <div className="flex flex-col gap-1 md:gap-5">
                <div className="pic6 w-[160px] md:w-[188px]"></div>
                <div className="pic7 w-[160px] md:w-[288px]"></div>
              </div>
            </article>
          </section>
        </section>
        <section className="sec-5">
          <div className="sec5heading">Our Partners</div>
          <div className="flex gap-3">
            {Partners.map((partner) => {
              return (
                <div className="sec5partner" key={partner.name}>
                  {partner.name}
                </div>
              );
            })}
          </div>
        </section>
        <section className="footer">
          {/* <div className="ft-heading">Sydney.so</div> */}
          <div className="flex items-center gap-1">
            {/* <MailOutlined /> contact@sydney.so */}© 2024 Sydney.so All
            rights reserved.
          </div>
        </section>
        <Modal
          title="Referral code "
          open={modalTrigger}
          centered
          footer={null}
          onCancel={() => setmodalTrigger(false)}
        >
          <p>
            Entering a code is optional but it unlocks rewards. Generate or use
            a provided code. You will not see this code again. Before joining,
            write it down or click <b>Share</b> for a link containing the code.
            The codes used most often will receive the most benefits.
          </p>
        </Modal>
        <Modal
          title="Representing a business ?"
          open={modalTriggerInfo}
          centered
          footer={null}
          onCancel={() => setmodalTriggerInfo(false)}
        >
          <p>
            If you are an owner, manager or otherwise represent a business,
            please check this box to hear about promotion and other business
            opportunities such as networking events”.
          </p>
        </Modal>
      </section>
    </div>
  );
};

export default LandingPage;
